.all {
    display: grid;
    grid-template-columns: auto 1fr; /* Updated */
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0;
}

/* Navbar.tsx CSS */

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px; /* Adjust width according to your needs */
    height: 100vh;
    background-color: #fff;
    border-right: 1px solid rgba(39, 94, 254, 0.2);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    z-index: 9999;
}
a {
    position: fixed;
    text-decoration: none;
    color: inherit;
    margin: 0;
    padding: 0;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgba(39, 94, 254, 0.2);
  }

.logotext {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header .logotext img{
    margin-bottom: 20px;
    margin-top: 20px;
    transition: all 0.5s ease;
    transform: scale(0.9);
}

#header .logotext:hover img {
    transform: scale(1);
}

#header .pro-sidebar-content {
    display: flex;
    justify-content: center;
}

#header .pro-sidebar-inner {
    background-color: transparent;
}

#header .pro-icon {
    position: relative; 
    color:#03045F;
    animation: none;
}

#header .pro-icon-wrapper {
    background-color: transparent;
    transition: all 0.5s ease;
    padding: 12px;
    margin-bottom: 0;
    transform: scale(0.9);
}

#header .pro-icon-wrapper:hover {
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

#header .pro-icon-wrapper:hover .icon-meme {
    color:#03045F;
    
}

#header .pro-sidebar .pro-menu a {
    position: relative; 
    color:#275EFE;
    transition: all 0.5s ease;
    height: 24px;
}

#header .pro-sidebar.collapsed {
    width: 80px;
}

#header .pro-icon img {
    border-radius: 10%;
    transition: all 0.3s ease;
}

#header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 8px 15px 0px 20px;
}

.IconSelected {
    background-color: #f1f1f1;
}


    #header .pro-icon-wrapper:hover {
        transition: all 0.5s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1);
        
        .icon-meme {
           
            color:#03045F;
        }
    }
    #header .pro-sidebar .pro-menu a {
        position: relative; 
        color:#275EFE;
        transition: all 0.5s ease;
        height: 24px;
    }

 

    #header .pro-sidebar.collapsed {
        width: 80px;
      }
  
  #header .pro-icon img {
    border-radius: 10%;
    transition: all 0.3s ease;
  }
  #header .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 8px 15px 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #header .pro-sidebar-footer .pro-icon-wrapper {
    transform: scale(0.9);
  }
  #header .pro-sidebar-footer .pro-icon-wrapper:hover {
    background-color: #fff;
    transform: scale(1);
  }

.box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.box .container {
    height: 100vh;
    width: 100%;
    background: linear-gradient(to top right, white 20%, #93E1F0 100%) fixed no-repeat;
    background: url(../../assets/background.png) no-repeat fixed;
    background-size: cover;
    display: grid;
    grid-template-columns: 25% 75%;
}


@media screen and (min-width: 720px) and (max-width: 1080px) {
    .box .container {
        grid-template-columns: 25% 75%;
    }
}

._0vzh {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

._0vzh .clags-roe {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

._0vzh .clags-roe div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(29, 49, 98);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

._0vzh .clags-roe div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* Media Queries */

@media screen and (max-width: 720px) {
    .all {
        grid-template-columns: 100%;
    }

    .box {
        width: 100vw;
    }

    .box .container {
        grid-template-columns: 25% 75%;
    }
    #header {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    
    .box .container {
        grid-template-columns: 10 90%;
    }

    ._0vzh .clags-roe {
        width: 60px;
        height: 60px;
    }

    ._0vzh .clags-roe div {
        width: 10px;
        height: 10px;
    }
}
