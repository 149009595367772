.formulaire {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formulaire .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}


.formulaire .navigation > div {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 10px;
    user-select: none;
}

.formulaire .navigation > div span {
    padding-right: 8px;
}

.formulaire .container {
    display: flex;
    justify-content: center;
    overflow-y: auto;
}

.formulaire .contenu {
    height: 100vh;
    padding: 40px;
    margin-bottom: 40px;
    color: rgb(12, 12, 20);
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.formulaire .contenu .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}

.formulaire .contenu .header h2 {
    all: unset;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 12px;
}    
.formulaire .contenu .header h2 strong {
    background: #03045F; 
    background: -webkit-linear-gradient(to top, #03045F, #00B6DA); 
    background: linear-gradient(to right, #03045F 0%, #2C4E7D 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
    font-family: "Galey 1", sans-serif;
}

.formulaire .contenu .header span {
    color: rgb(112, 109, 107);
    font-size: 14px;
}

.formulaire .contenu inputs-group > div {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.formulaire .contenu inputs-group span {
    font-size: 15px;
    margin-bottom: 7px;
    font-weight: 500;
}

.formulaire .contenu inputs-group input {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    padding-left: 15px;
    font-size: 15px;
    border: 2px solid #5889CF;
    box-sizing: border-box;
    outline: none;
    transition: .2s;
}

.formulaire .contenu inputs-group input:focus {
    border: 2px solid #03045F;
}

.formulaire .contenu inputs-group .praised-yald {
    position: absolute;
    align-items: center;
    top: 35px;
    right: 10px;
    color: rgb(112, 109, 107);
    font-size: 15px;
    border-radius: 4px;
}

.formulaire .contenu inputs-group .praised-yald svg {
    color: rgb(80, 80, 80);
    padding-left: 5px;
    transform: translateY(2px);
    cursor: pointer;
}

.formulaire .contenu inputs-group .spastics-foe {
    position: relative;
}

.formulaire .contenu inputs-group .spastics-foe .sanitise-oxen {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    bottom: -20px;
    left: 0px;
    color: rgb(220, 50, 81);
    font-size: 11px;
    font-weight: 500;
}

.formulaire .contenu inputs-group .spastics-foe .sanitise-oxen svg {
    width: 13px;
    height: 13px;
}

.formulaire .contenu inputs-group .spastics-foe .eastern-memo {
    color: rgb(62, 62, 62);
    font-size: 26px;
    caret-color: transparent;
}

.formulaire .contenu inputs-group .spastics-foe input.error {
    border-color: rgb(220, 50, 81);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.formulaire .contenu .redirection {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(190, 190, 190);
}

.formulaire .contenu .redirection img {
    width: 20px;
    height: 20px;
    transform: translateY(2px);
}

.formulaire .contenu .redirection button {
    width: 100%;
    height: 44px;
    margin-top: 24px;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: "Galey 1";
    font-size: 16px;
    font-weight: 500;
    background-color: #03045F;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    border: 1px solid #03045F;
}

.formulaire .contenu .redirection button:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #03045F, inset -4px -4px 12px #fff;
}

.formulaire .contenu .redirection button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.formulaire .contenu .redirection button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: rgb(205, 238, 246);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.formulaire .contenu .redirection button:hover {
    color: #000000;
    border: 1px solid #03045F;
}

.formulaire .contenu .redirection button:hover:before {
    top: -35%;
    background-color: rgb(205, 238, 246);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.formulaire .contenu .redirection button:hover:after {
    top: -45%;
    background-color: rgb(205, 238, 246);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.formulaire .contenu .redirection.spinner button {
    background-color: rgba(205, 238, 246, 0.70);
}

.formulaire .contenu .redirection > div {
    width: 100%;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}

.formulaire .contenu .redirection > div span {
    color: #0078B8;
    text-decoration: underline;
    cursor: pointer;
}

.formulaire .contenu .redirection > div span:hover {
    text-decoration: none;
}

.formulaire .contenu .domes-pout {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: rgb(112, 109, 107);
    margin-top: 20px;
}

.formulaire .contenu .domes-pout span:nth-of-type(2) {
    color: rgb(0, 120, 184);
    text-decoration: underline;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
    .formulaire .container {
        flex-direction: column;
        align-items: center;
    }

    .formulaire .contenu {
        padding: 20px;
    }

    .formulaire .contenu inputs-group input {
        width: 100%;
    }
    .formulaire .navigation {
        padding: 5px 10px;
        height: 5%;
    }
    
    .formulaire .navigation > div {
        background-color: white;
        padding: 5px 10px;

    }
}
