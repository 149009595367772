@font-face {
  font-family: "Against";
  src: url('./assets/against_regular.ttf') format('truetype');
}
@font-face {
  font-family: "Galey 1";
  src: url('./assets/Galey Semi Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Galey 2";
  src: url('./assets/Galey Rounded Extra Light.ttf') format('truetype');
}
@font-face {
  font-family: "Open sans";
  src: url('./assets/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}
@font-face {
  font-family: "Nano";
  src: url('./assets/nano.ttf') format('truetype');
}

html {
  background: linear-gradient(to top right, white 20%, #93E1F0 100%) fixed no-repeat;
  background: url(./assets/background.png) no-repeat fixed;
  background-size: cover;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family : -apple-system, BlinkMacSystemFont,'Open sans', 'Segoe UI Emoji', "Galey 1";
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
    overflow: auto;
}

body{
	margin: 0 auto;
}









