.Container {
  display: grid;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  height: 100vh;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: hsla(0, 0%, 10%, 0);
  background-color: white;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid rgba(39, 94, 254, 0.2);
}

.user-details {
  display: flex;
  align-items: center;
  gap: 1rem;
}


.username h3 {
  color: #275EFE;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.username h5 {
  margin-top: 0.3rem;
  color: rgb(170, 170, 170);
}

.chat-messages {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  animation: slide-up 0.5s ease-in-out;
}

.chat-messages::-webkit-scrollbar {
  width: 0.2rem;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #ffffff39;
  width: 0.1rem;
  border-radius: 1rem;
}

.date-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101010;
}

.message {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  gap: 0.4rem;
  flex-direction: column;
}

.surname { 
  order: -1; /* Move the surname to the top */
}

.avatar {
    overflow: hidden;
}
  
.avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}
.avatars  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.15rem;
  height: 3.15rem;
  border-radius: 50%;
  overflow: hidden;
}


.avatars img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}




.message .content {
  max-width: 40%;
  overflow-wrap: break-word;
  padding: 0.01rem 1rem;
  font-size: 1rem;
  border-radius: 1rem;
  color: white;
}

.message .content .text {
  margin-block-start: 0.6em;
  margin-block-end: 0.6em;
}


.message .content .photoEnvoyee {
  max-width: 40%;
  overflow-wrap: break-word;
  font-size: 1rem;
  border-radius: 1rem;
  color: white;
  transition: all 0.5s ease;
}


.message .date {
  display: flex;
  font-size: 0.8rem;
  margin-top: -1rem;
  justify-content: flex-end;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  color: #101010;
  
}



.sended {
  justify-content: flex-end;
  align-items: flex-end;
}

.sended .content {
  background-color: #275EFE;
  .date {
    color: rgb(190, 190, 190);
  }
}

.recieved {
  justify-content: flex-start;
}

.recieved .content {
  background-color: hsla(0, 0%, 10%, 0.1);
  backdrop-filter: blur(12px);
  color: #101010;
  .date {
    color: rgb(90, 90, 90);
  }
}

@media screen and (min-width: 720px) and (max-width: 1080px) {
  .message .content {
    max-width: 70%;
  }
}



@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.typing {
  width: 3.5rem;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: hsla(0, 0%, 10%, 0.1);
  border-radius: 20px;
  display: flex;
  padding-right: 0;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #101010;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 720px) {
  .Container {
    grid-template-rows: 10% 80% 10%;
  }

  .username h3 {
    margin-top: 1rem;
  }

  .username h5 {
    margin-top: 0.3rem;
  }

  .message .content {
    max-width: 90%;
  }
  
  .chat-messages {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    animation: slide-up 0.5s ease-in-out;
  }
}
