.formulaire {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: auto;
}

.formulaire .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.formulaire .navigation > div {
    background-color: hsla(0, 0%, 10%, 0);
    backdrop-filter: blur(8px);
    border: 1px solid hsla(0, 0%, 100%);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 10px;
    user-select: none;
    color: #03045F;
}

.formulaire .navigation > div span {
    padding-right: 8px;
    padding-left: 8px;
}

.formulaire .container {
    display: flex;
    justify-content: center;
   
}

.formulaire .contenu {
    height: auto;
    max-width: 35%;
    padding: 40px;
    margin-bottom: 40px;
    color: rgb(12, 12, 20);
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: hsla(0, 0%, 10%, 0);
    backdrop-filter: blur(8px);
    border: 2px solid hsla(0, 0%, 100%);
}

.formulaire .contenu .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}

.formulaire .contenu .header h2 {
    all: unset;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 12px;
}


.formulaire .contenu .header span {
    color: rgb(112, 109, 107);
    font-size: 14px;
}

.formulaire .contenu .header span .neutrino {
    background: #03045F; 
    background: -webkit-linear-gradient(to top, #03045F, #00B6DA); 
    background: linear-gradient(to right, #03045F 0%, #2C4E7D 100%); /* light theme */
    /*background: linear-gradient(to right, #5889CF 0%, #19CCFB 100%); /* dark theme */ 
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.formulaire .contenu .inputs-group > div {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.formulaire .contenu .inputs-group span {
    font-size: 15px;
    margin-bottom: 7px;
    font-weight: 500;
}

.formulaire .contenu .inputs-group input {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    padding-left: 15px;
    font-size: 15px;
    border: 2px solid #5889CF;
    box-sizing: border-box;
    outline: none;
    transition: .2s;
}

.formulaire .contenu .inputs-group input:focus {
    border: 2px solid #03045F;
}

.formulaire .contenu .inputs-group .praised-yald {
    position: absolute;
    top: 35px;
    right: 10px;
    color: rgb(112, 109, 107);
    font-size: 15px;
}

.formulaire .contenu .inputs-group .praised-yald svg {
    color: rgb(80, 80, 80);
    padding-left: 5px;
    transform: translateY(2px);
    cursor: pointer;
}

.formulaire .contenu .inputs-group .gumdrops-ace {
    position: relative;
}

.formulaire .contenu .inputs-group .gumdrops-ace .reemploy-pion {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    bottom: -20px;
    left: 0px;
    color: rgb(220, 50, 81);
    font-size: 11px;
    font-weight: 500;
}

.formulaire .contenu .inputs-group .gumdrops-ace .mondays-gees {
    color: rgb(62, 62, 62);
    font-size: 26px;
    caret-color: transparent;
}

.formulaire .contenu .inputs-group .gumdrops-ace input.error {
    border-color: rgb(220, 50, 81);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.formulaire .contenu .redirection {
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(190, 190, 190);
}
.formulaire .contenu .redirection button {
    width: 100%;
    height: 44px;
    margin-top: 24px;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: "Galey 1";
    font-size: 16px;
    font-weight: 500;
    background-color: #03045F;
    box-sizing: border-box;
    transition: all 0.1s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    border: 1px solid #03045F;
}

.formulaire .contenu .redirection button:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #03045F, inset -4px -4px 12px #fff;
}

.formulaire .contenu .redirection button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.formulaire .contenu .redirection button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.formulaire .contenu .redirection button:hover {
    color: #03045F;
    border: 2px solid #03045F;
}

.formulaire .contenu .redirection button:hover:before {
    top: -35%;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.formulaire .contenu .redirection button:hover:after {
    top: -45%;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.formulaire .contenu .redirection button img {
    width: 20px;
    height: 20px;
    transform: translateY(2px);
}


.formulaire .contenu .redirection > div {
    width: 100%;
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}

.formulaire .contenu .redirection > div span {
    color: rgb(98, 67, 230);
    text-decoration: underline;
    cursor: pointer;
}

.formulaire .contenu .redirection > div span:hover {
    text-decoration: none;
}

.formulaire .contenu .domes-pout {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: rgb(112, 109, 107);
    margin-top: 20px;
}

.formulaire .contenu .domes-pout span {
    font-size: 15px;
    color: #0078B8;
    text-decoration: underline;
}

.formulaire .contenu .realise-rid > div:first-of-type {
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
}

.formulaire .contenu .realise-rid > div:first-of-type svg {
    width: 18px;
    height: 18px;
    fill: rgb(98, 67, 230);
    margin-left: 5px;
    transform: translateY(1px);
}

.formulaire .contenu .realise-rid .checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border: 1px solid rgb(154, 154, 154);
    border-radius: 5px;
    margin-right: 8px;
}

.formulaire .contenu .realise-rid .checkbox.active {
    border-color: #03045F;
    background-color: #03045F;
}

.formulaire .contenu .realise-rid .checkbox .uprootal-lug {
    position: absolute;
    left: -4px;
    width: 17px;
    height: 17px;
    fill: white;
}

.formulaire .contenu .prankish-sod {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-left: 29px;
    margin-top: 5px;
    font-size: 15px;
    color: rgb(112, 109, 107);
}

.formulaire .contenu .prankish-sod .marrows-chew {
    text-decoration: underline;
} 

._0vzh {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

._0vzh .clags-roe {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
._0vzh .clags-roe div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(29, 49, 98);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

._0vzh .clags-roe div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

._0vzh .clags-roe div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}


@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}


@media screen and (max-width: 480px) {
    .formulaire {
        overflow-y: auto;
        backdrop-filter: blur(8px);
    }
    .formulaire .container {
        flex-direction: column;
        align-items: center;
    }

    .formulaire .contenu {
        height: auto;
        border: none;
        background-color: hsla(0, 0%, 10%, 0);
        backdrop-filter: blur(0px);
        box-shadow: none;
    }
    

    .formulaire .contenu .inputs-group input {
        width: 100%;
    }
    .formulaire .navigation {
        padding: 5px 10px;
        height: 10vh;
    }
    
    .formulaire .navigation > div {
        padding: 5px 10px;
    }
}